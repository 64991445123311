













































import {Component} from 'vue-property-decorator';
import {email, required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import {ROUTE_PASSWORD_REQUESTED, ROUTE_WELCOME_REQUESTED} from "@/router/routes";
import AuthRepository from "@/api/repositories/AuthRepository";
import UserRepository from "@/api/repositories/UserRepository";

@Component({
  mixins: [validationMixin],
  validations: {
    email: { required, email},
  }
})
export default class InitiateResetPasswordView extends mixins(ErrorMessageHandlerMixin) {
  /**
   * touched flag, is set when user enters first input, shrinks the details of the input field to avoid big padding
   * @private
   */
  private touched = false;

  /**
   * email that is entered
   * @private
   */
  private email = '';

  /**
   * is called when user clicks on the back button, pops back to last screen
   */
  public onBackPressed() {
    this.$router.back();
  }

  /**
   * is called when login button is tapped, validates login of the user
   * @private
   */
  private async onResendWelcomeMail() {
    // Trigger validation
    this.$v.$touch();

    if (this.$v.$invalid) {
      this.touched = true;
      return;
    }

    try {
      await UserRepository.resendWelcomeMail(this.email);
      await this.$router.push({name: ROUTE_WELCOME_REQUESTED});
    } catch(e) {
      // Handle any incoming errors
      this.$handleError(e, async () => {
        switch (e.status) {
          case 403:
            await this.$router.push({name: ROUTE_WELCOME_REQUESTED});
            break;
          default:
            this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.EMAIL_WAS_NOT_SENT");
        }
      });
    }
  }
}
